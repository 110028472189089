import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const GetAFleetQoute = () => {
  const [citydata, setcitydata] = useState([]);
  const [id, setid] = useState(0);
  function getData2(ele) {
    setid(ele.stateId._id);
    document.getElementById("city").value = ele.city;
    document.getElementById("state").value = ele.stateId.name;
    document.getElementById("zipCode").value = ele.zipCode;

    setcitydata([]);
  }
  const handlercity = (event) => {
    if (event.target.value !== "") {
      axios
        .get(
          `${process.env.REACT_APP_API_base_KEY}/getData2?flag=3&&citySearch=${event.target.value}`
        )
        .then((res) => {
          if (res.data.data.data.length > 0) {
            setcitydata(res.data.data);
          } else {
            setcitydata([]);
          }
        })
        .catch((err) => {});
    } else {
      setcitydata([]);
    }
  };
  return (
    <Formik
      initialValues={{
        type: 17,
        email: "",
        firstName: "",
        lastName: "",
        city: "",
        zipCode: "",
        companyName: "",
        stateId: "",
        phone: "",
        fleetSize: "",
        fleetType: "",
      }}
      onSubmit={(values, action) => {
        var state1 = id;
        values.stateId = state1;
        var zip = document.getElementById("zipCode").value;
        values.zipCode = zip;
        var city = document.getElementById("city").value;
        values.city = city;
        action.resetForm();
        axios
          .post(`${process.env.REACT_APP_API_base_KEY}/form`, values)
          .then((res) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Thank you!!",
              text: "Your interest has been submitted to us. Our team will get back to you soon!!",
              showConfirmButton: false,
              timer: 4000,
            });
            document.getElementById("zipCode").value = "";
            document.getElementById("city").value = "";
            document.getElementById("state").value = "";
          })
          .catch((err) => {});
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Please enter a valid email")
          .required("Email required"),
        firstName: Yup.string()
          .matches(/^[A-Za-z ]*$/, "Please enter valid firstname")
          .required("First name required"),
        lastName: Yup.string()
          .matches(/^[A-Za-z ]*$/, "Please enter valid lastname")
          .required("Last name Required"),
        companyName: Yup.string()
          .matches(/^[A-Za-z ]*$/, "Please enter valid companyname")
          .required("Company name required"),
        city: Yup.string().required("City name required"),
        phone: Yup.string()
          .matches(phoneRegExp, "Phone number is not valid")
          .required("Phone number required"),
        fleetSize: Yup.string().required("fleetsize is required"),
        fleetType: Yup.string().required("fleettype is required"),
      })}
    >
      {(props) => {
        const {
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <>
            <div className="">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6 form-group">
                    <label>First Name*</label>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      className="form-control"
                      id="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {errors.firstName && touched.firstName && (
                        <span className="input-feedback">
                          {errors.firstName}
                        </span>
                      )}{" "}
                    </p>
                  </div>
                  <div className="col-sm-6 form-group">
                    <label>Last Name*</label>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      className="form-control"
                      id="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {errors.lastName && touched.lastName && (
                        <span className="input-feedback">
                          {errors.lastName}
                        </span>
                      )}{" "}
                    </p>
                  </div>
                  <div className="col-md-12 form-group">
                    <label>Email Address*</label>
                    <input
                      type="email"
                      placeholder="Enter Email Address"
                      className="form-control"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {" "}
                      {errors.email && touched.email && (
                        <span className="input-feedback">{errors.email}</span>
                      )}
                    </p>
                  </div>
                  <div className="col-md-12 form-group">
                    <label>Company Name*</label>
                    <input
                      type="text"
                      placeholder="Enter Company Name"
                      className="form-control"
                      id="companyName"
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {errors.companyName && touched.companyName && (
                        <span className="input-feedback">
                          {errors.companyName}
                        </span>
                      )}{" "}
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-sm-6 form-group position-relative">
                    <label>City*</label>
                    <input
                      type="text"
                      placeholder="Enter City"
                      className="form-control"
                      id="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyUp={(e) => handlercity(e)}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {errors.city && touched.city && (
                        <span className="input-feedback">{errors.city}</span>
                      )}{" "}
                    </p>
                    {citydata.length !== 0 && (
                      <ul className="zipcode-listing">
                        {citydata.length !== 0 &&
                          citydata.data.map((ele, i) => {
                            return (
                              <li key={i} onClick={() => getData2(ele)}>
                                {ele?.city}
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </div>
                  <div className="col-sm-6 form-group">
                    <label>State*</label>
                    <input
                      type="text"
                      placeholder="Enter State"
                      className="form-control"
                      id="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {errors.state && touched.state && (
                        <span className="input-feedback">{errors.state}</span>
                      )}{" "}
                    </p>
                  </div>
                  <div className="col-sm-6 form-group">
                    <label>Zip Code*</label>
                    <input
                      type="text"
                      placeholder="Enter Zip Code"
                      className="form-control"
                      id="zipCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {" "}
                      {errors.zipCode && touched.zipCode && (
                        <span className="input-feedback">{errors.zipCode}</span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-6 form-group">
                    <label>Phone*</label>
                    <input
                      type="text"
                      placeholder="Enter Phone"
                      className="form-control"
                      id="phone"
                      maxLength={10}
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {" "}
                      {errors.phone && touched.phone && (
                        <span className="input-feedback">{errors.phone}</span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-6 form-group">
                    <label>Fleet Size*</label>
                    <input
                      type="text"
                      placeholder="Enter Fleet Size"
                      className="form-control"
                      id="fleetSize"
                      value={values.fleetSize}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {" "}
                      {errors.fleetSize && touched.fleetSize && (
                        <span className="input-feedback">
                          {errors.fleetSize}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-6 form-group">
                    <label>Fleet Type*</label>
                    <input
                      type="text"
                      placeholder="Enter Fleet Type"
                      className="form-control"
                      id="fleetType"
                      value={values.fleetType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {" "}
                      {errors.fleetType && touched.fleetType && (
                        <span className="input-feedback">
                          {errors.fleetType}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button
                      className="btn btn--primary px-5 mx-auto w-100 mt-3"
                      id="submit"
                      value="Submit"
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};
export default GetAFleetQoute;
