import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { userlogo } from "../Imagesjs/Images";
import "./BlogComments.css";
import BlogLeaveComment from "./BlogLeaveComment";
import axios from "axios";
import moment from "moment";

function BlogComments(props) {
  const [apidata, setapidata] = useState("");
  const [isPreviewShown, setPreviewShown] = useState(null);
  const [showform, setshowform] = useState(true);
  const [commentid, setcommentid] = useState("");
  const [childdata, setchilddata] = useState(false);
  var id1 = props.getid;
  useEffect(() => {
    listComments();
  }, []);
  const selectbox = (e, i) => {
    if (e) {
      setcommentid(e.target.id);
      setchilddata(true);
    }

    e.preventDefault();
    setPreviewShown(i);
    setshowform(false);
  };

  const removebox = (e) => {
    e.preventDefault();
    setPreviewShown(false);
    setshowform(true);
  };

  const listComments = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_base_KEY}/getData2?flag=4&&isPublished=true&&isArticle=false&&blogId=` + props.getid
      )
      .then((res) => {
        setapidata(res.data.data.data[0].comments);
      })
      .catch((err) => {});
  };

  const submitComment = (values) => {
    if (childdata) {
      values.commentId = commentid;
      values.isChildComment = true;
    }

    axios
      .put("https://apidev.gasitup.com/admin/addCommentBlog", values)
      .then((res) => {
        listComments();
        setPreviewShown(false);
        setshowform(true);
      })
      .catch((err) => {});
  };
  return (
    <>
      <section>
        <div className="container bootstrap snippets bootdey">
          <div className="row">
            <div className="col-md-12">
              <div className="blog-comment">
                <h3 className="text-white">Comments</h3>
                <hr />
                <ul className="comments blogcomments">
                  {apidata.length > 0 &&
                    apidata.map((ele, i) => (
                      <li className="clearfix" key={i}>
                        <img
                          src="https://bootdey.com/img/Content/user_1.jpg"
                          className="avatar"
                          alt="avatar"
                        />
                        <div className="post-comments">
                          <div className="meta">
                            <Link to="">{ele.commentBy}</Link>
                            <i className="">
                              <Link to="">
                                <small
                                  id={ele._id}
                                  onClick={(e) => selectbox(e, i)}
                                >
                                  Reply
                                </small>
                              </Link>
                            </i>
                            <br />
                            <small>
                              {" "}
                              {moment(ele.createdOn).format("DD/MM/YYYY")}{" "}
                            </small>
                          </div>
                          <p>{ele?.text}</p>
                        </div>
                        {ele.childComments.map((childComment) => (
                          <li className="clearfix" key={i}>
                            <img
                              src="https://bootdey.com/img/Content/user_1.jpg"
                              className="avatar"
                              alt="avatar"
                            />
                            <div className="post-comments">
                              <div className="meta">
                                <Link to="">{childComment.commentBy}</Link>
                                <i className="">
                                  <Link to="">
                                    <small
                                      id={ele._id}
                                      onClick={(e) => selectbox(e, i)}
                                    >
                                      Reply
                                    </small>
                                  </Link>
                                </i>
                                <br />
                                <small>
                                  {" "}
                                  {moment(childComment.createdOn).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                </small>
                              </div>
                              <p>{childComment?.text}</p>
                            </div>
                          </li>
                        ))}
                        {isPreviewShown === i && (
                          <div
                            className="contact-form getfleetqouteblock p-3 position-relative "
                            style={{ overflow: "inherit" }}
                          >
                            <div className="heading-style">
                              <button
                                onClick={(e) => removebox(e)}
                                className="close-button"
                              >
                                X
                              </button>
                              <h4 className="text-white mb-0">
                                Leave a Comments:
                              </h4>
                              <p>
                                Your Email Address will not be Published. please
                                fill the required Fields.
                              </p>
                            </div>

                            <BlogLeaveComment
                              submitComment={submitComment}
                              id2={id1}
                            />
                          </div>
                        )}
                      </li>
                    ))}

                  <li className="clearfix">
                    <ul className="comments">
                      <li className="clearfix">
                        <img src={userlogo} className="avatar" alt="JohnDoe" />
                        <div className="post-comments">
                          <p className="meta">
                            <Link to="">JohnDoe</Link>
                            <i className="">
                              <Link to="">
                                <small>Reply</small>
                              </Link>
                            </i>
                            <br />
                            <small>Dec 20, 2014 At 2:15AM </small>
                          </p>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Etiam a sapien odio, sit amet
                          </p>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {showform && (
          <div className="heading-style">
            <h4 className="text-white mb-0">Leave a Comments:</h4>
            <p>
              Your Email Address will not be Published. please fill the required
              Fields.
            </p>
          </div>
        )}
        {showform && (
          <div className="contact-form getfleetqouteblock p-3">
            <BlogLeaveComment
              apidata={apidata}
              id2={id1}
              submitComment={submitComment}
            />
          </div>
        )}
      </section>
    </>
  );
}

export default BlogComments;
