import React,{ useEffect,useState}  from "react";
import { Link } from "react-router-dom";
import { calendar, moblogo, search } from "../Imagesjs/Images";
import './Sidebar.css'
import axios from "axios";
import moment from 'moment'

function Sidebar(props) {
  const [data, setData] = useState([])
	const [APIData, setAPIData] = useState([])
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [currentTab, setCurrentTab] = useState(props.pathname);

  // const [activeId, setActiveId] = useState();


  const searchItems = (searchValue) => {
  setSearchInput(searchValue)
  if (searchInput !== '') {
  // const filteredData = data.filter((item) => {
  // return Object.values(item.title).join('').toLowerCase().includes(searchInput.toLowerCase())
  // })

    axios.get(`${process.env.REACT_APP_API_base_KEY}/getData2?flag=4&&search=`+searchValue).then(res => {
      setData(res.data.data.data);
      }).catch(err => {
      });
 
  setFilteredResults(data)
  }
  else{
  setFilteredResults(APIData)
  }
  }
   useEffect(() => {
  axios.get(`${process.env.REACT_APP_API_base_KEY}/getData2?flag=4&&limit=4&&isPublished=true`).then(res => {
    setAPIData(res.data.data.data);
    }).catch(err => {
    });
  }, []);

  const onItemClick = (event,id) => {
    event.preventDefault();
    for (const li of document.querySelectorAll("li.nav-item")) {
      li.classList.remove("active");
    }
    event.currentTarget.classList.toggle("active");
    
    let query = {};

    if(id === 'all') {
      // query = {
      //   isPublished: true,
      //   isArticle: true,
      // };
    }
    else if(id === 'upcoming') {
      query = {
        isPublished: false
      };
    }
    else if(id === 'related') {
      query = {
        isPublished: true,
        isArticle: true,
      };
    }
    else if(id === 'blog') {
      query = {
        isPublished: true,
        isArticle: false,
      };
    }

    if(props.handleCategoryChange) {
      props.handleCategoryChange(query);

    }

    setCurrentTab(id)
    
  };

  useEffect(() => {
    if(window.location.pathname.endsWith('/blog')) {
      setCurrentTab(props.pathname);
    }
  },[props.pathname]);


  return (
    <>
      <div className="col-md-4 sidebar">
        <div className="widget widget-search">
          <form action="#" method="get" acceptCharset="utf-8">
            <input type="text" name="widget-search" placeholder="Search"  autoComplete="off"
            onKeyUp={(e) => props.handleChange ? setTimeout(() => props.handleChange(e), 100)  : setTimeout(() => searchItems(e.target.value), 100) }
            />
			<i className=""><img src={search} alt="search" height={20}/></i>
          </form>
        </div>
        <div className="widget widget-categories">
          <div className="widget-title">
            <h3>Categories</h3>
          </div>
          <ul className="cat-list">
            <li className={`nav-item w3_megamenu fw ${currentTab === 'all' ? 'active': ''} wo-we2`} onClick={(e) => onItemClick(e,"all")}>
              <Link  to={{ pathname: "/blog",search: "?sort=all"}}>All</Link>
            </li>
            <li onClick={(e) => onItemClick(e,"upcoming")} className ={`${currentTab === 'upcoming' ? 'active': ''}`}>
              <Link to={{ pathname: "/blog",search: "?sort=upcoming"}}>Upcoming Events</Link>
            </li>
            <li onClick={(e) => onItemClick(e,"related")} className ={`${currentTab === 'related' ? 'active': ''}`} >
              <Link to={{ pathname: "/blog",search: "?sort=related"}}>Related Articles</Link>
            </li>
            <li onClick={(e) => onItemClick(e,"blog")} className ={`${currentTab === 'blog' ? 'active': ''}`}>
              <Link to={{ pathname: "/blog",search: "?sort=blog"}}>Blog Posts</Link>
            </li>
          </ul>
        </div>
        <div className="widget widget-blog">
          <div className="widget-title">
            <h3>Recent Blog</h3>
          </div>
          <ul className="cat-list">
            { searchInput.length > 1 ? ( filteredResults.map((NewsList, i) => (
              <li
                className="wow fadeInUp"
                data-wow-delay={`0.` + i + `s`}
                key={i}
              >
                <div className="blogblock col-md-12 mb-5">
                  <div className="row align-items-start">
                    <div className="col-7">
                      <figure>
                        <img
                          src={NewsList.imageUrl.original}
                          alt="NewsList"
                          className="img-fluid w-100"
                        />
                      </figure>
                    </div>
                    <div className="col-5">
                      <h4 className="mt-0">{NewsList.title}</h4>
                      <div className="meta-details">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-md-12 admin-details mb-2">
                            <i>
                              <img src={moblogo} alt="client" />
                            </i>
                            <span>
                              Posted By:
                              <span className="text-orange">
                                {" "}
                                Admin
                              </span>
                            </span>
                          </div>
                          <div className="col-md-12 posted-details text-start mb-2 d-flex align-items-center justify-content-start">
                            <i>
                              <img src={calendar} alt="calendar" />
                            </i>
                            <span>{moment(NewsList.createdOn).format("DD/MM/YYYY")}</span>
                          </div>
                        </div>
                      </div>

                      <Link to={`/blogdetail/${NewsList._id}`} target="_blank" className="text-orange">
                        <span>Read More ...</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ))):( APIData.map((NewsList, i) => (
              <li
                className="wow fadeInUp"
                data-wow-delay={`0.` + i + `s`}
                key={i}
              >
                <div className="blogblock col-md-12 mb-md-5 mb-3">
                  <div className="row align-items-start">
                    <div className="col-sm-7 col-6">
                      <figure>
                        <img
                          src={NewsList.imageUrl.original}
                          alt="NewsList"
                          className="img-fluid w-100"
                        />
                      </figure>
                    </div>
                    <div className="col-sm-5 col-6">
                      <h4 className="mt-0">{NewsList.title}</h4>
                      <div className="meta-details">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-md-12 admin-details mb-2">
                            <i>
                              <img src={moblogo} alt="client" />
                            </i>
                            <span>
                              Posted By:
                              <span className="text-orange">
                                {" "}
                                Admin
                              </span>
                            </span>
                          </div>
                          <div className="col-md-12 posted-details text-start mb-2 d-flex align-items-center justify-content-start">
                            <i>
                              <img src={calendar} alt="calendar" />
                            </i>
                            <span>{moment(NewsList.createdOn).format("DD/MM/YYYY")}</span>
                          </div>
                        </div>
                      </div>

                      <Link to={`/blogdetail/${NewsList._id}`} target="_blank" className="text-orange">
                        <span>Read More ...</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            )))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;