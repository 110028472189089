import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import Contact from "../Contactus/Contact";
import axios from "axios";
import moment from "moment";
// import Paginationer from "../Pagination/Paginationer";
import {
  blog1,
  blog2,
  calendar,
  careersbanner,
  leadershipcontactbanner,
  moblogo,
} from "../Imagesjs/Images";
import "./ViewBlog.css";
import Sidebar from "../Sidebar/Sidebar";

// let PageSize = 10;
function ViewBlog() {
  var pathname = window.location.search.substring(6);

  const [data, setData] = useState([]);
  const contactbanner = leadershipcontactbanner;
  const [pageCount, setPageCount] = useState(10);
  const [pagenumber, setpagenumber] = useState(0);
  // const [itemOffset, setItemOffset] = useState(0);
  const [APIData, setAPIData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  // const [publishedEvents, setPublishedEvents] = useState(true);
  const [isArticles, setisArticles] = useState();
  const [text, settext] = useState("All");
  const [active, setactive] = useState("text-white");
  const [inactive, setinactive] = useState("text-muted");
  const [set, set1] = useState(true);
  const handleChange = (event) => {
    setSearchInput(event.target.value);
    if (event.target.value !== "") {
      // const filteredData = data.filter((item) => {
      // return Object.values(item.title).join('').toLowerCase().includes(searchInput.toLowerCase())
      // })

      axios
        .get(
          `${process.env.REACT_APP_API_base_KEY}/getData2?flag=4&&isPublished=true&&search=` +
            event.target.value
        )
        .then((res) => {
          setData(res.data.data.data);

          setPageCount(res.data.data.count);

          setpagenumber(Math.round(res.data.data.count) / 7);
        })
        .catch((err) => {});

      setFilteredResults(data);
    } else {
      setFilteredResults(APIData);
    }
  };

  const pagename = {
    name: "Blog",
  };
  const News = [
    {
      banner: blog1,
      title: "GIU Conference",
      postedby: "Admin",
      Des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...",
      linktitle: "Read More",
    },
    {
      banner: blog2,
      title: "GIU Press Meet",
      postedby: "Admin",
      Des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...",
      linktitle: "Read More",
    },
    {
      banner: blog2,
      title: "GIU Conference",
      postedby: "Admin",
      Des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...",
      linktitle: "Read More",
    },
    {
      banner: blog1,
      title: "GIU Conference",
      postedby: "Admin",
      Des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...",
      linktitle: "Read More",
    },
    {
      banner: blog2,
      title: "GIU Conference",
      postedby: "Admin",
      Des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...",
      linktitle: "Read More",
    },
    {
      banner: blog2,
      title: "GIU Conference",
      postedby: "Admin",
      Des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...",
      linktitle: "Read More",
    },
  ];

  // useEffect(() => {
  //   const params = {
  //     flag: 4,
  //     limit: 7,
  //     isPublished: publishedEvents,
  //     isArticle: isArticles,
  //   };

  //   axios
  //     .get("https://apidev.gasitup.com/web/getData2", { params })
  //     .then((res) => {
  //       setPageCount(res.data.data.count);
  //       setpagenumber(pageCount / 7);
  //       Math.round(pagenumber);
  //       setAPIData(res.data.data.data);
  //     })
  //     .catch((err) => {});
  // }, [publishedEvents]);

  useEffect(() => {
    let vr = {
      isPublished: true,
      // isArticles :false
    };
    if (pathname === "related") {
      const angle = ` ${"related" ? "text-white" : "text-muted d-inline"}`;
      setactive(angle);
      const angle1 = ` ${"related" ? "text-muted " : "text-white d-inline"}`;
      setinactive(angle1);
      settext("Related Articles");
      vr = {
        isPublished: true,
        isArticle: true,
      };
    }
    if (pathname === "upcoming") {
      const angle = ` ${"upcoming" ? "text-white" : "text-muted d-inline"}`;
      setactive(angle);
      const angle1 = ` ${"upcoming" ? "text-muted " : "text-white d-inline"}`;
      setinactive(angle1);
      settext("Upcoming Events");
      vr = {
        isPublished: false,
      };
    }
    if (pathname === "blog") {
      const angle = ` ${"blog" ? "text-white" : "text-muted d-inline"}`;
      setactive(angle);
      const angle1 = ` ${"blog" ? "text-muted " : "text-white d-inline"}`;
      setinactive(angle1);
      settext("Blog Posts");
      vr = {
        isPublished: true,
        isArticle: false,
      };
    }
    set1(vr);
    const params = {
      flag: 4,
      limit: 9,
      ...vr,
    };

    axios
      .get(`${process.env.REACT_APP_API_base_KEY}/getData2`, { params })
      .then((res) => {
        setPageCount(res.data.data.count);
        setpagenumber(pageCount / 7);
        Math.round(pagenumber);
        setAPIData(res.data.data.data);
      })
      .catch((err) => {});
  }, [isArticles,pageCount,pagenumber,pathname]);

  // const handlePageClick = (event) => {
  //   const skip = (event.selected * 7) % pageCount;
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_base_KEY}/getData2?flag=4&&skip=${skip}&limit=${7}`
  //     )
  //     .then((res) => {
  //       setPageCount(res.data.data.count);
  //       setpagenumber(pageCount / 7);
  //       Math.round(pagenumber);
  //       setAPIData(res.data.data.data);
  //     })
  //     .catch((err) => {});
  //   // setItemOffset(skip);
  // };

  const handleCategoryChange = (query) => {
    const angle = ` ${query ? "text-white" : "text-muted d-inline"}`;
    const angle1 = ` ${query ? "text-muted " : "text-white d-inline"}`;

    setactive(angle);
    setinactive(angle1);
    if (query.isArticle === false && query.isPublished === true) {
      settext("Blog Posts");
    }  else if (query.isPublished === false) {
      settext("Upcoming Events");
    } else if (query.isArticle === true && query.isPublished === true) {
      settext("Related Articles");
    }
    else {
      settext("All");
    }

    set1(query);
    const params = {
      flag: 4,
      limit: 9,
      ...query,
    };

    axios
      .get(`${process.env.REACT_APP_API_base_KEY}/getData2`, { params })
      .then((res) => {
        setPageCount(res.data.data.count);
        setpagenumber(pageCount / 7);
        Math.round(pagenumber);
        setAPIData(res.data.data.data);
      })
      .catch((err) => {});
  };

  return (
    <>
      <section className="inner-banner section-banner faq">
        <div className="background-image">
          <img src={careersbanner} alt="careers banner" />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-12  text-center">
              <BreadCrumb pagename={pagename} />
            </div>
            <div className="col-12">
              <div className="home-banner-texter text-center">
                <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                  <span>Blog</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all blog-details tabing-list pb-0">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-start align-items-center mb-3">
                <li className="breadcrumb-item">
                  <h3>
                    <Link to="/" className={`${inactive}`}>
                      Home
                    </Link>
                  </h3>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <h5 className={`${active} d-inline `}>{text}</h5>
                </li>
              </ol>
            </nav>
            <div className="col-md-8">
              <div className="row align-items-center justify-content-center">
                {searchInput.length > 1
                  ? filteredResults.map((NewsList, i) => (
                      <div
                        className="wow fadeInUp blogblock col-md-6 mb-5"
                        data-wow-delay={"0." + i + "s"}
                        key={i}
                      >
                        <figure>
                          <img
                            src={NewsList.imageUrl.original}
                            alt="NewsList"
                            className="img-fluid w-100"
                          />
                        </figure>
                        <h4>{NewsList.title}</h4>
                        <div className="meta-details">
                          <div className="row align-items-center justify-content-between">
                            <div className="col-md-6 admin-details">
                              <i>
                                <img src={moblogo} alt="client" />
                              </i>
                              <span>
                                Posted By:
                                <span className="text-orange"> Admin</span>
                              </span>
                            </div>
                            <div className="col-md-6 posted-details d-flex align-items-center justify-content-end text-end">
                              <i>
                                <img src={calendar} alt="calendar" />
                              </i>
                              <span>
                                {moment(NewsList.createdOn).format(
                                  "MM/DD/YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <p className="pt-3">
                        {NewsList.subTitle.substring(0, 121) + "...."}
                        </p>
                        {set.isPublished === false ||
                        set.isPublished === true ? (
                          ""
                        ) : (
                          <Link
                            to={`/blogdetail/${NewsList._id}`}
                            className="btn btn--primary--outline d-inline-flex px-4"
                          >
                            <span>Read More ...</span>
                          </Link>
                        )}

                        {set.isPublished === true && set.isArticle === false ? (
                          <Link
                            to={`/blogdetail/${NewsList._id}`}
                            className="btn btn--primary--outline d-inline-flex px-4"
                          >
                            <span>Read More ...</span>
                          </Link>
                        ) : (
                          ""
                        )}
                        {set.isPublished === true && set.isArticle === true ? (
                          <a
                            href={NewsList.articleUrl}
                            className="btn btn--primary--outline d-inline-flex px-4"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>Read More ...</span>
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    ))
                  : APIData.map((NewsList, i) => (
                      <div
                        className="wow fadeInUp blogblock col-md-6 mb-5"
                        data-wow-delay={"0." + i + "s"}
                        key={i}
                      >
                        <div className="d-block">
                          {NewsList.imageUrl.original &&
                          (NewsList.imageUrl.original
                            .split(/[#?]/)[0]
                            .split(".")
                            .pop()
                            .trim() === "jpg" ||
                            NewsList.imageUrl.original
                              .split(/[#?]/)[0]
                              .split(".")
                              .pop()
                              .trim() === "jpeg" ||
                            NewsList.imageUrl.original
                              .split(/[#?]/)[0]
                              .split(".")
                              .pop()
                              .trim() === "png") ? (
                            <figure>
                              <img
                                src={NewsList.imageUrl.original}
                                alt="NewsList"
                                className="img-fluid w-100"
                              />{" "}
                            </figure>
                          ) : (
                            <iframe
                              width="100%"
                              height="250"
                              src={NewsList.imageUrl.original}
                              frameBorder="0"
                              title="unique"
							                allowTransparency="true"
                              allowFullScreen={true}
                              style={{ borderRadius: "0px" }}
                            ></iframe>
                          )}
                        </div>

                        <h4>{NewsList.title}</h4>
                        <div className="meta-details">
                          <div className="row align-items-center justify-content-between">
                            <div className="col-md-6 admin-details">
                              <i>
                                <img src={moblogo} alt="client" />
                              </i>
                              <span>
                                Posted By:
                                <span className="text-orange"> Admin</span>
                              </span>
                            </div>
                            <div className="col-md-6 posted-details d-flex align-items-center justify-content-end text-end">
                              <i>
                                <img src={calendar} alt="calendar" />
                              </i>
                              <span>
                                {moment(NewsList?.publishedBy).format(
                                  "MM/DD/YYYY"
                                )
                                  ? moment(NewsList?.publishedBy).format(
                                      "MM/DD/YYYY"
                                    )
                                  : moment(NewsList?.createdOn).format(
                                      "MM/DD/YYYY"
                                    )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <p className="pt-3">
                         {NewsList.subTitle.substring(0, 121) + "...."}
                        </p>
                        {set.isPublished === false ||
                        set.isPublished === true ? (
                          ""
                        ) : (
                          <Link
                            to={`/blogdetail/${NewsList._id}`}
                            className="btn btn--primary--outline d-inline-flex px-4"
                          >
                            <span>Read More ...</span>
                          </Link>
                        )}
                        {set.isPublished === true && set.isArticle === false ? (
                          <Link
                            to={`/blogdetail/${NewsList._id}`}
                            className="btn btn--primary--outline d-inline-flex px-4"
                          >
                            <span>Read More ...</span>
                          </Link>
                        ) : (
                          ""
                        )}

                        {set.isPublished === true && set.isArticle === true ? (
                          <a
                            href={NewsList.articleUrl}
                            className="btn btn--primary--outline d-inline-flex px-4"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>Read More ...</span>
                          </a>
                        ) : (
                          ""
                        )}
                        {set.isPublished === false && set.isArticle === true ? (
                          <Link
                            to={`/blogdetail/${NewsList._id}`}
                            className="btn btn--primary--outline d-inline-flex px-4"
                          >
                            <span>Read More ...</span>
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                {/* <div>
                  <Paginationer
                    variant="outlined"
                    color="primary"
                    handlePageClick={handlePageClick}
                    pageCount={pagenumber}
                  />
                </div> */}
                 {!APIData.length ? <span className="text-start">Coming Soon</span>:null}
              </div>
            </div>

            <Sidebar
              News={News}
              handleChange={handleChange}
              handleCategoryChange={handleCategoryChange}
              setisArticles={setisArticles}
              pathname={pathname}
            />
            {/* <div className="pagination">
            <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      /></div> */}
          </div>
        </div>
      </section>
      <section className="common-section-all">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Get into contact with a{" "}
                  <span className="text-orange">Gas It Up</span> professional
                  that
                  <br /> can get your{" "}
                  <span className="text-orange">business</span> set up for{" "}
                  <span className="text-orange">success</span>
                </h5>
              </div>
            </div>
            <Contact
              contactbanner={contactbanner}
              type={1}
              showBusinessFields={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default ViewBlog;
