/*Home*/
// export { default as image1 } from "../assets/images/image1.jpg";
const image1 =`${process.env.React_App_cdn_url}/image1.jpg`
const image2 = `${process.env.REACT_APP_AWS_URL}/Pic_961410111816.jpg`;
const image3 = `${process.env.REACT_APP_AWS_URL}/Pic_1209871522595.jpg`;
const image3desel = `${process.env.REACT_APP_AWS_URL}/Pic_205454103596.jpg`;
const image4 = `${process.env.REACT_APP_AWS_URL}/Pic_1073050598375.jpg`;
const image5 = `${process.env.REACT_APP_AWS_URL}/Pic_437190006669.jpg`;
const image6 = `${process.env.REACT_APP_AWS_URL}/Pic_756664295775.jpg`;
const image7 = `${process.env.REACT_APP_AWS_URL}/Pic_1035299834506.jpg `;
const image8 = `${process.env.REACT_APP_AWS_URL}/Pic_1646775155336.jpg `;
const image9 = `${process.env.REACT_APP_AWS_URL}/Pic_144766541524.jpg `;
const image10 = `${process.env.REACT_APP_AWS_URL}/Pic_130581477591.jpg `;
const image11 = `${process.env.REACT_APP_AWS_URL}/Pic_764851165979.jpg `;
const geoofencing = `${process.env.REACT_APP_AWS_URL}/Pic_1037908147694.jpg`;
const iphonescreen = `${process.env.REACT_APP_AWS_URL}/Pic_403530818945.png`;
const bannerimg = `${process.env.REACT_APP_AWS_URL}/Pic_609184326717.png`;
const smalliframe = `${process.env.REACT_APP_AWS_URL}/Pic_743926371898.png`;
export { default as bannermacbookimg } from "../assets/images/macbook.png";
export { default as phone } from "../assets/images/img1.png";
export { default as arrow } from "../assets/images/arrow_right.svg";
export { default as videoimage } from "../assets/images/videoimage.jpg";
const consumer = `${process.env.REACT_APP_AWS_URL}/Pic_845420940701.png`;
const business = `${process.env.REACT_APP_AWS_URL}/Pic_400822965047.png`;
const movingtruckwhite =`${process.env.React_App_cdn_url}/truckwhite.mp4`
const movingtruck =`${process.env.React_App_cdn_url}/truck.mp4`
const b2bfleetsrender =`${process.env.React_App_cdn_url}/b2bfleetsrender.mp4`
const adminpanel = `${process.env.React_App_cdn_url}/adminpanel.mp4`
const adminpanelwebm =`${process.env.React_App_cdn_url}/adminpanel.webm`
export { default as trackingbg } from "../assets/images/tracking.jpg";
export { default as trackingbgwhite } from "../assets/images/trackingbgwhite.svg";
export { default as imgleft } from "../assets/images/img-left.png";
export { default as horn } from "../assets/images/horn.svg";
export { default as mute } from "../assets/images/volumemute.svg";
export { default as unmute } from "../assets/images/soundicon.svg";
export { default as play } from "../assets/images/play-icon.svg";
export { default as pause } from "../assets/images/pause-icon.svg";
export { default as linehorn1 } from "../assets/images/hornline1.svg";
export { default as linehorn2 } from "../assets/images/hornline2.svg";
export { default as linehorn3 } from "../assets/images/hornline3.svg";
export { default as linehorn4 } from "../assets/images/hornline4.svg";
export { default as linehorn5 } from "../assets/images/hornline5.svg";
export { default as linehorn6 } from "../assets/images/linehorn5.svg";
export { default as linehorn7 } from "../assets/images/linehorn1.svg";
export { default as linehorn8 } from "../assets/images/linehorn2.svg";
export { default as linehorn9 } from "../assets/images/linehorn3.svg";
export { default as linehorn10 } from "../assets/images/linehorn4.svg";
export { default as hornright } from "../assets/images/horn-right.svg";
const ios0 = `${process.env.REACT_APP_AWS_URL}/Pic_1609822990412.jpg `;
const ios1 = `${process.env.REACT_APP_AWS_URL}/Pic_1591767866391.jpg `;
const ios2 = `${process.env.REACT_APP_AWS_URL}/Pic_320988979146.jpg `;
export { default as ios3 } from "../assets/images/ios3.jpg";
const ios4 = `${process.env.REACT_APP_AWS_URL}/Pic_1559471086561.jpg`;
const ios5 = `${process.env.REACT_APP_AWS_URL}/Pic_148024937659.jpg`;
const ios6 = `${process.env.REACT_APP_AWS_URL}/Pic_120972111593.jpg`;
const ios7 = `${process.env.REACT_APP_AWS_URL}/Pic_1102777229777.jpg`;
export { default as ios8 } from "../assets/images/ios8.jpg";
const ios9 = `${process.env.REACT_APP_AWS_URL}/Pic_179697165885.jpg`;
const ios10 = `${process.env.REACT_APP_AWS_URL}/Pic_480510130603.jpg`;
const ios11 = `${process.env.REACT_APP_AWS_URL}/Pic_1456733378079.jpg`;
const ios12 = `${process.env.REACT_APP_AWS_URL}/Pic_704821105547.jpg`;
const download = `${process.env.REACT_APP_AWS_URL}/Pic_770272620927.jpg`;
const schedule = `${process.env.REACT_APP_AWS_URL}/Pic_1548736384052.jpg`;
const relax = `${process.env.REACT_APP_AWS_URL}/Pic_1264860324531.jpg`;
const chargeituplogo = `${process.env.REACT_APP_AWS_URL}/Pic_1219143716956.png`;
const california = `${process.env.REACT_APP_AWS_URL}/Pic_38388331531.png`;
const Colorado = `${process.env.REACT_APP_AWS_URL}/Pic_178988045114.png`;
const florida = `${process.env.REACT_APP_AWS_URL}/Pic_527124480760.png`;
export { default as michigan } from "../assets/images/michigan.png";
export { default as texas } from "../assets/images/texas.png";
export { default as newyork } from "../assets/images/newyork.png";
const downloadicon = `${process.env.REACT_APP_AWS_URL}/Pic_934393867239.png`;
const scheduleicon = `${process.env.REACT_APP_AWS_URL}/Pic_66264235516.png`;
const relaxicon = `${process.env.REACT_APP_AWS_URL}/Pic_1000834193055.png`;
export { default as logoanimated } from "../assets/images/logoanimated.gif";

/**/
const energyinnovation = `${process.env.REACT_APP_AWS_URL}/Pic_377594501371.jpg`;

/*partners*/
const img1 = `${process.env.REACT_APP_AWS_URL}/Pic_412542814643.png`;
const img2 = `${process.env.REACT_APP_AWS_URL}/Pic_1438948628127.png`;
const img4 = `${process.env.REACT_APP_AWS_URL}/Pic_194577004674.png`;
const img5 = `${process.env.REACT_APP_AWS_URL}/Pic_995146346248.png`;
const img6 = `${process.env.REACT_APP_AWS_URL}/Pic_972548375069.png`;
const img7 = `${process.env.REACT_APP_AWS_URL}/Pic_735352380164.png`;
export { default as img8 } from "../assets/images/logo8.png";
const img9 = `${process.env.REACT_APP_AWS_URL}/Pic_1270416699598.png`;
const img10 = `${process.env.REACT_APP_AWS_URL}/Pic_640390048971.png`;
const img11 = `${process.env.REACT_APP_AWS_URL}/Pic_464943057000.png`;
const img12 = `${process.env.REACT_APP_AWS_URL}/Pic_732250593187.png`;
const img13 = `${process.env.REACT_APP_AWS_URL}/Pic_1514603294514.png`;
const img14 = `${process.env.REACT_APP_AWS_URL}/Pic_361395088822.png`;
export { default as broncos } from "../assets/images/broncos.png";
/*Testimonial*/

export { default as star } from "../assets/images/star.svg";
export { default as testimoniallogo } from "../assets/images/testimonial.svg";

/*Footer*/
export { default as footerlogo } from "../assets/images/guilogo.svg";
export { default as email } from "../assets/images/email.svg";
export { default as phoneicon } from "../assets/images/phone.svg";
export { default as insta } from "../assets/images/insta.svg";
export { default as twitter } from "../assets/images/twitter.svg";
export { default as facebook } from "../assets/images/facebook.svg";
export { default as Linkdin } from "../assets/images/linkdin.svg";
export { default as youtube } from "../assets/images/youtube.svg";
export { default as tictok } from "../assets/images/tictok.svg";
export { default as arrowup } from "../assets/images/arrowup.svg";

/*Dashboard*/
export { default as check } from "../assets/images/point-icon.svg";
const checkwhite = `${process.env.REACT_APP_AWS_URL}/Pic_978085139056.png`;

/*Header*/
export { default as logo } from "../assets/images/guilogo.svg";
export { default as moblogo } from "../assets/images/guilogobkp.svg";
export { default as logolight } from "../assets/images/logo_light.svg";
export { default as moblogolight } from "../assets/images/moblogo_light.svg";

/*coorporate*/
const copratebanner = `${process.env.REACT_APP_AWS_URL}/Pic_745307825488.jpg`;
export { default as hotel } from "../assets/images/hotels.svg";
export { default as hospitals } from "../assets/images/hospitals.svg";
export { default as officeparks } from "../assets/images/office-parks.svg";
export { default as airport } from "../assets/images/airport.svg";
export { default as school } from "../assets/images/school.svg";
export { default as fleet } from "../assets/images/fleet.svg";
export { default as shopping } from "../assets/images/shopping.svg";
const eglestadium = `${process.env.REACT_APP_AWS_URL}/Pic_340837723373.jpg`;
const academicleaderbanner =
  "https://s3-us-west-2.amazonaws.com/gasitupbucket/Pic_572840769636.jpg";

/*B2c*/
export { default as stadium } from "../assets/images/stadium.svg";

/* benefits */
const benefitsbanner = `${process.env.REACT_APP_AWS_URL}/Pic_1508645938840.jpg`;
export { default as freedombanner } from "../assets/images/freedombanner.jpg";

/* future */
export { default as futureprofessionalsbanner } from "../assets/images/futureprofessionalsbanner.jpg";

/*Certified banner*/
const certifiedbanner = `${process.env.REACT_APP_AWS_URL}/Pic_773713672683.jpg`;
const certifiedicon = `${process.env.React_App_cdn_url}/certified-icon1.png`;
const certifiedicon2 = `${process.env.React_App_cdn_url}/certified-icon2.png`;

/*Retail Surface*/
export { default as retailbanner1 } from "../assets/images/retailbanner1.png";
export { default as retailbanner2 } from "../assets/images/retailbanner2.png";
export { default as retailbanner3 } from "../assets/images/retailbanner3.png";
export { default as gasbanner } from "../assets/images/need-gasbanner.jpg";
// export { default as gasthumb } from "../assets/images/need-gasthumb.png";
const gasthumb =`${process.env.React_App_cdn_url}/need-gasthumb.png`
export { default as gasthumbretail } from "../assets/images/need-gasthumbbkp.png";
export { default as retailcontactbanner } from "../assets/images/retailcontactbanner.jpg";

/*Loction icon*/
export { default as cuilocation } from "../assets/images/cui_location.svg";
export { default as activelocation } from "../assets/images/active_location.svg";
export { default as upcominglocation } from "../assets/images/upcomming_location.svg";
export { default as ciu } from "../assets/images/chargeitup_location.svg";
export { default as fleetmanagment } from "../assets/images/fleet-management.svg";
export { default as fueltank } from "../assets/images/fueltank.svg";
const buildpumpbanner = `${process.env.REACT_APP_AWS_URL}/Pic_518910400759.jpg`;
export { default as fueltechnicians } from "../assets/images/fuel-technicians.jpg";
export { default as def } from "../assets/images/def.svg";
export { default as renewableenergy } from "../assets/images/renewable-energy.svg";
export { default as gasoline } from "../assets/images/gasoline.svg";
export { default as gasolineeffeciency } from "../assets/images/gasolineeffeciency.jpg";
const buildserve = `${process.env.REACT_APP_AWS_URL}/Pic_294935573410.jpg`;
export { default as lytx } from "../assets/images/lytx.svg";
export { default as esri } from "../assets/images/esri.png";
export { default as verizonconnect } from "../assets/images/verizonconnect.png";
export { default as wex } from "../assets/images/wex.png";
export { default as comdata } from "../assets/images/comdata.png";
export { default as voyager } from "../assets/images/voyager.png";
export { default as skymark } from "../assets/images/skymark.svg";
export { default as largetanker } from "../assets/images/largetanker.svg";

/* geoofensing */
export { default as geoofensingbanner } from "../assets/images/geoofensingbanner.png";
export { default as geoofencingtechnology } from "../assets/images/geoofencingtechnology.jpg";
export { default as ourtechgeofencing } from "../assets/images/ourtechgeofencing.jpg";
export { default as geoofencingtechno } from "../assets/images/geoofencingtech.png";

/*Desel DEF*/
export { default as Deselbanner } from "../assets/images/desel-def.png";
export { default as fuelyourfleet } from "../assets/images/fuelyourfleet.svg";
const deselanddefcontactbanner = `${process.env.REACT_APP_AWS_URL}/Pic_1027512968062.jpg`;
export { default as deselefficiencydelivery } from "../assets/images/deselefficiencydelivery.jpg";

/*testimonials*/
export { default as test1 } from "../assets/images/test1.png";
export { default as test2 } from "../assets/images/test2.png";
export { default as test3 } from "../assets/images/test3.png";
/*Video*/

/*gasoline*/

export { default as gasolinecontactbanner } from "../assets/images/gasolinecontactbanner.jpg";
/*faq*/
const careersbanner = `${process.env.REACT_APP_AWS_URL}/Thumb_813714709917.jpg`;
const appstack = `${process.env.REACT_APP_AWS_URL}/Pic_1638450875488.jpg`;
export { default as ourfueltech } from "../assets/images/ourfueltech.jpg";
export { default as ourfueltechmission } from "../assets/images/ourfueltechmission.jpg";
export { default as newsbanner } from "../assets/images/newsbanner.jpg";
export { default as facebookicon } from "../assets/images/facebook_icon.svg";
export { default as twittericon } from "../assets/images/twitter_icon.svg";
export { default as instaicon } from "../assets/images/insta_icon.svg";

/*Service */
export { default as icon1 } from "../assets/images/icon1.svg";
export { default as icon2 } from "../assets/images/icon2.svg";
export { default as icon3 } from "../assets/images/icon3.svg";
export { default as icon4 } from "../assets/images/icon4.svg";
export { default as icon5 } from "../assets/images/icon5.svg";
export { default as icon6 } from "../assets/images/icon6.svg";
export { default as icon12 } from "../assets/images/icon12.svg";
export { default as icon13 } from "../assets/images/icon13.svg";
export { default as icon14 } from "../assets/images/icon14.svg";
export { default as icon15 } from "../assets/images/icon15.svg";
export { default as icon16 } from "../assets/images/icon16.svg";
export { default as icon20 } from "../assets/images/icon20.png";
export { default as icon17 } from "../assets/images/icon17.svg";
export { default as icon18 } from "../assets/images/icon18.svg";
export { default as icon19 } from "../assets/images/icon19.svg";

/*blog*/
const blog1 = `${process.env.REACT_APP_AWS_URL}/Pic_309606625721.jpg`;
const blog2 = `${process.env.REACT_APP_AWS_URL}/Pic_1194478494339.jpg`;
const client = `${process.env.REACT_APP_AWS_URL}/Pic_723808901939.png`;
export { default as userlogo } from "../assets/images/userlogo.png";
export { default as calendar } from "../assets/images/calendar.svg";

/* telematic */
export { default as telematicbanner } from "../assets/images/telematicbanner.jpg";
export { default as telematiccontactbanner } from "../assets/images/telematicontactbanner.jpg";
export { default as getgassed } from "../assets/images/getgassed.jpg";

/* ourfuel and safety */
export { default as safety } from "../assets/images/safety.svg";
export { default as safetybanner } from "../assets/images/safety_banner.jpg";
export { default as ourfueltechcontactbanner } from "../assets/images/ourfueltechcontactbanner.jpg";
export { default as oshalogo } from "../assets/images/osha-logo.svg";
const driverappgallons = `${process.env.REACT_APP_AWS_URL}/Pic_972327138182.jpg`;
export { default as innovatingfuturebanner } from "../assets/images/innovatingfuturebanner.jpg";
export { default as ourfueltechthumb } from "../assets/images/outfueltechthumb.png";
export { default as ourfueltechbg } from "../assets/images/outfueltechbg.png";
export { default as ourfueltechbgwhite } from "../assets/images/ourfueltechbgwhite.png";
export { default as ourfueltechnicians } from "../assets/images/ourfueltechnicians.jpg";

/* Enviroment */
export { default as gaseffect } from "../assets/images/gaseffect.jpg";
const enviromentimpactcontactbanner = `${process.env.REACT_APP_AWS_URL}/Pic_1092740978923.jpg`;
const enviromentimpactbg = `${process.env.REACT_APP_AWS_URL}/Pic_1102769016142.png`;
const enviromentimpactthumb = `${process.env.REACT_APP_AWS_URL}/Pic_1609012818164.png`;
export { default as giu } from "../assets/images/GIUCO2.jpg";
export { default as enviromentimpactbgwhite } from "../assets/images/enviromentimpactbgwhite.png";

/* change Fuel */
const changefuel = `${process.env.REACT_APP_AWS_URL}/Pic_101738467731.jpg`;
const foundationbanner = `${process.env.REACT_APP_AWS_URL}/Pic_191848939679.jpg`;
const foundationbanner1 = `${process.env.REACT_APP_AWS_URL}/Pic_883406314648.jpg`;
const foundationbanner2 = `${process.env.REACT_APP_AWS_URL}/Pic_394019576157.jpg`;
export { default as foundationlogo } from "../assets/images/foundationlogo.svg";
export { default as api } from "../assets/images/api.svg";
export { default as energy } from "../assets/images/energy.svg";
export { default as licensing } from "../assets/images/licensing.png";
const changefuelingcontactbanner = `${process.env.REACT_APP_AWS_URL}/Pic_882466553806.jpg`;

/* Team */
export { default as owner } from "../assets/images/owner.jpg";
const david = `${process.env.REACT_APP_AWS_URL}/Pic_1232046123332.jpg`;
export { default as fred } from "../assets/images/fred.jpg";
export { default as sean } from "../assets/images/sean.jpg";
export { default as greygory } from "../assets/images/greygory.jpg";
export { default as richard } from "../assets/images/richard.jpg";
export { default as gregorie } from "../assets/images/gregorie.jpg";
export { default as phillip } from "../assets/images/phillip.jpg";
export { default as lance } from "../assets/images/lance.jpg";
export { default as michael } from "../assets/images/michael.jpg";
export { default as lisa } from "../assets/images/lisa.jpg";
const avery = `${process.env.REACT_APP_AWS_URL}/Pic_1029757354049.jpg`;
const eliza = `${process.env.REACT_APP_AWS_URL}/Pic_453430926814.jpg`;
export { default as gaskins } from "../assets/images/gaskins.jpg";
export { default as jeff } from "../assets/images/jeff.jpg";
export { default as steve } from "../assets/images/steve.jpg";
export { default as tim } from "../assets/images/tim.jpg";
export { default as geoff } from "../assets/images/geoff.jpg";
export { default as leaderdummy } from "../assets/images/leaderdummy.jpg";
export { default as leaderdummygirl } from "../assets/images/leaderdummygirl.jpg";
export { default as elias } from "../assets/images/Bishop Barreto.jpg";

/* Contact us */
const contactus = `${process.env.REACT_APP_AWS_URL}/Pic_259843651246.jpg`;

/*LeaderTeam */
export { default as leaderbanner } from "../assets/images/leaderbanner.png";
export { default as polygonlarge } from "../assets/images/polygonlarge.svg";
export { default as polygonsmall } from "../assets/images/polygonsmall.svg";
// export { default as leadershipcontactbanner } from "../assets/images/leadershipcontactbanner.jpg";
const leadershipcontactbanner =`${process.env.React_App_cdn_url}/leadershipcontactbanner.jpg`
export { default as linkdinblue } from "../assets/images/linkdin-blue.svg";
export { default as priscopanza } from "../assets/images/prisco-panza.jpg";

/* Our Process */
export { default as ourprocessbanner } from "../assets/images/ourprocessbanner.svg";
export { default as ourprocessefficiency } from "../assets/images/ourprocessefficiency.jpg";
export { default as ourprocesscontactbanner } from "../assets/images/ourprocesscontactbanner.jpg";
export { default as terminal } from "../assets/images/terminal.svg";
export { default as terminalwhite } from "../assets/images/terminalwhite.png";
export { default as jobber } from "../assets/images/jobber.svg";
export { default as carrierwhite } from "../assets/images/carrierwhite.png";
export { default as storagetank } from "../assets/images/storagetank.svg";
export { default as storagetankwhite } from "../assets/images/storagetankwhite.png";
export { default as gasstation } from "../assets/images/gasstation.svg";
export { default as gasstationwhite } from "../assets/images/gasstationwhite.png";
export { default as diseltruck } from "../assets/images/diseltruck.svg";
export { default as diseltruckwhite } from "../assets/images/diseltruckwhite.png";
const crosspurple = `${process.env.REACT_APP_AWS_URL}/Pic_441929669580.png`;
export { default as nojobber } from "../assets/images/nojobber.png";
export { default as nojobberwhite } from "../assets/images/nojobberwhite.png";
export { default as nostorage } from "../assets/images/nostorage.svg";
export { default as nostoragewhite } from "../assets/images/nostoragewhite.png";
export { default as nostations } from "../assets/images/nostations.svg";
export { default as nostationswhite } from "../assets/images/nostationswhite.png";
export { default as middletruck } from "../assets/images/middletruck.png";
export { default as Speedometerfull } from "../assets/images/Speedometerfull.png";
export { default as Speedometerfullwhite } from "../assets/images/Speedometerfullwhite.png";
export { default as needdle_holder } from "../assets/images/needdle_holder.png";
export { default as needdle } from "../assets/images/needdle.png";
export { default as eagles2ndstadium } from "../assets/images/eaglesstad.png";
export { default as gamestadium } from "../assets/images/newstad.png";

/* search */
export { default as search } from "../assets/images/search.svg";

/* geoofencing */
export { default as geoofencingcontactbanner } from "../assets/images/geoofencingcontactbanner.jpg";
export { default as geoofencingcontactbannernew } from "../assets/images/geoofencingcontactbannernew.jpg";

/* stateoffleet */
const stateoffleetcontactbanner = `${process.env.REACT_APP_AWS_URL}/Pic_1603904846945.jpg`;

/*autonmous banner */
export { default as autonomousfleetbanner } from "../assets/images/autonomousfleetbanner.svg";
const autonomousfleet = `${process.env.REACT_APP_AWS_URL}/Pic_93787056182.jpg`;
const autonomuscontactbanner = `${process.env.REACT_APP_AWS_URL}/Pic_1438297700687.jpg`;

/*ChargeItup*/

const chargeItUpbanner = `${process.env.REACT_APP_AWS_URL}/Pic_1035254526408.png`;
export { default as chargeItUpicon } from "../assets/images/chargeituplogo.svg";
export { default as chargeItUpiconwhite } from "../assets/images/chargeItUpiconwhite.png";
export { default as checkgreen } from "../assets/images/checkgreen.svg";
const chargeItUpbannerthumb = `${process.env.REACT_APP_AWS_URL}/Pic_1053342279790.jpg`;
const cuiappicon1 = `${process.env.REACT_APP_AWS_URL}/Pic_29313027901.png`;
const cuiappicon2 = `${process.env.REACT_APP_AWS_URL}/Pic_1666397351881.png`;
const cuiappicon3 = `${process.env.REACT_APP_AWS_URL}/Pic_1340442148463.png`;
const cuiappicon4 = `${process.env.REACT_APP_AWS_URL}/Pic_684768640893.png`;
const cuiappbanner1 = `${process.env.REACT_APP_AWS_URL}/Pic_1502290240867.jpg`;
const cuiappbanner2 = `${process.env.REACT_APP_AWS_URL}/Pic_1657564973797.jpg`;
const cuiappbanner3 = `${process.env.REACT_APP_AWS_URL}/Pic_911639449812.jpg`;
const cuiappbanner4 = `${process.env.REACT_APP_AWS_URL}/Pic_654099277673.jpg`;
const cuislideicon1 = `${process.env.REACT_APP_AWS_URL}/Pic_1074405705315.png`;
const cuislideicon2 = `${process.env.REACT_APP_AWS_URL}/Pic_31340216100.png`;
const cuislideicon3 = `${process.env.REACT_APP_AWS_URL}/Pic_444023146630.png`;
const cuislideicon4 = `${process.env.REACT_APP_AWS_URL}/Pic_1434096081230.png`;
const cuislideicon5 = `${process.env.REACT_APP_AWS_URL}/Pic_760642370477.png`;
const cuislideicon6 = `${process.env.REACT_APP_AWS_URL}/Pic_283213863116.png`;
const cuislideicon7 = `${process.env.REACT_APP_AWS_URL}/Pic_227492187876.png`;
const cuislideicon8 = `${process.env.REACT_APP_AWS_URL}/Pic_347168834338.png`;
const cuislideicon9 = `${process.env.REACT_APP_AWS_URL}/Pic_1622967497354.png`;
const cuislideicon10 = `${process.env.REACT_APP_AWS_URL}/Pic_46314403395.png`;
const cuislideicon11 = `${process.env.REACT_APP_AWS_URL}/Pic_1622509039410.png`;
const cuislideicon12 = `${process.env.REACT_APP_AWS_URL}/Pic_548015603928.png`;
const cuislideicon13 = `${process.env.REACT_APP_AWS_URL}/Pic_1316919782440.png`;

/*business fleet */
const businesfleetbanner = `${process.env.REACT_APP_AWS_URL}/Pic_635486667044.jpg`;
const businessfleetcontactbanner = `${process.env.REACT_APP_AWS_URL}/Pic_522023126795.jpg`;

/*Coorporate fleet */
const egle1 = `${process.env.REACT_APP_AWS_URL}/Pic_866913851274.png`;
const egle2 = `${process.env.REACT_APP_AWS_URL}/Pic_817143192247.png`;

/*404 error*/
export { default as errormsg } from "../assets/images/errormsg.png";
export { default as arrowleft } from "../assets/images/arrowleft.png";

/*video section*/
const Videoblock = `${process.env.REACT_APP_AWS_VIDEOURL}/Profile_166850644174020611.mp4`;
const Videowebm = `${process.env.REACT_APP_AWS_VIDEOURL}/Profile_166850637961718581.webm`;
const b2bfleetsrenderweb = `${process.env.REACT_APP_AWS_VIDEOURL}/Profile_16685069269722591.webm`;
const corporatestadiums = `${process.env.REACT_APP_AWS_VIDEOURL}/Profile_166850661525618911.mp4`;
const corporatestadiumsweb = `${process.env.REACT_APP_AWS_VIDEOURL}/Profile_16685065242736641.webm`;

// export section
export {
  corporatestadiums,
  corporatestadiumsweb,
  b2bfleetsrenderweb,
  Videoblock,
  Videowebm,
  egle1,
  egle2,
  academicleaderbanner,
  cuiappbanner1,
  cuiappbanner2,
  cuiappbanner3,
  cuiappbanner4,
  cuiappicon1,
  cuiappicon2,
  cuiappicon3,
  cuiappicon4,
  cuislideicon1,
  cuislideicon2,
  cuislideicon3,
  cuislideicon4,
  cuislideicon5,
  cuislideicon6,
  cuislideicon7,
  cuislideicon8,
  cuislideicon9,
  cuislideicon10,
  cuislideicon11,
  cuislideicon12,
  cuislideicon13,
  businessfleetcontactbanner,
  businesfleetbanner,
  chargeItUpbannerthumb,
  chargeItUpbanner,
  autonomuscontactbanner,
  autonomousfleet,
  contactus,
  eliza,
  avery,
  david,
  foundationbanner,
  foundationbanner1,
  foundationbanner2,
  changefuelingcontactbanner,
  changefuel,
  enviromentimpactbg,
  enviromentimpactcontactbanner,
  enviromentimpactthumb,
  driverappgallons,
  client,
  blog2,
  blog1,
  appstack,
  careersbanner,
  deselanddefcontactbanner,
  buildserve,
  buildpumpbanner,
  certifiedicon2,
  certifiedicon,
  certifiedbanner,
  benefitsbanner,
  eglestadium,
  copratebanner,
  checkwhite,
  energyinnovation,
  downloadicon,
  florida,
  california,
  chargeituplogo,
  Colorado,
  download,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  ios0,
  ios1,
  ios2,
  ios4,
  ios5,
  ios6,
  ios7,
  ios9,
  ios10,
  ios11,
  ios12,
  img1,
  img2,
  img4,
  img5,
  img6,
  img7,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  scheduleicon,
  relaxicon,
  crosspurple,
  consumer,
  business,
  iphonescreen,
  bannerimg,
  smalliframe,
  geoofencing,
  relax,
  schedule,
  stateoffleetcontactbanner,
  image3desel,
  movingtruckwhite,
  movingtruck,
  b2bfleetsrender,
  adminpanel,
  adminpanelwebm,
  image1,
  gasthumb,
  leadershipcontactbanner
};
